var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { TableRow, TableCell, Skeleton, Stack } from '@mui/material';
// ----------------------------------------------------------------------
export default function TableSkeleton(_a) {
    var other = __rest(_a, []);
    return (_jsx(TableRow, __assign({}, other, { children: _jsx(TableCell, __assign({ colSpan: 12 }, { children: _jsxs(Stack, __assign({ spacing: 3, direction: "row", alignItems: "center" }, { children: [_jsx(Skeleton, { variant: "rectangular", width: 40, height: 40, sx: { borderRadius: 1, flexShrink: 0 } }), _jsx(Skeleton, { variant: "text", width: "100%", height: 20 }), _jsx(Skeleton, { variant: "text", width: 160, height: 20 }), _jsx(Skeleton, { variant: "text", width: 160, height: 20 }), _jsx(Skeleton, { variant: "text", width: 160, height: 20 }), _jsx(Skeleton, { variant: "text", width: 160, height: 20 })] })) })) })));
}
